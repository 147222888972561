<template>
    <div>
        <el-card style="min-height: 45rem">
            <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
                <el-form label-position="left" style="width: 26rem">
                    <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">知识付费基础配置</div>
                    <el-form-item label="全部分类图标">
                        <y_upload_img v-model="form.cate_all_pic"></y_upload_img>
                    </el-form-item>
                    <el-form-item align="right">
                        <el-button @click="edit" type="primary">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>

import y_upload_img from "@/components/y_upload/y_upload_img";

export default {
    name: "conf",
    components: {
        y_upload_img,
    },
    data () {
        return {
            form: {
                cate_all_pic: "",
            }
        }
    },
    mounted () {
        this.load();
    },
    methods: {
        load () {
            this.$api.addons.knowledge.conf().then( res => {
                this.form = res;
            } )
        },
        edit () {
          this.$api.addons.knowledge.confEdit( this.form ).then( () => {
                this.$message.success( "操作成功" );
                this.load();
            } )
        },
    }
}
</script>

<style scoped>

</style>